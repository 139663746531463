export default function () {

    const itemCounters = document.querySelectorAll('.product-counter');
    if (!itemCounters)
        return;

    itemCounters.forEach((itemCounter) => {

        const inputField = itemCounter.querySelector('input');
        itemCounter.querySelector('.plus').addEventListener('click', function () {
            inputField.value = Number(inputField.value) + 1;
        }, false);
        itemCounter.querySelector('.plus').addEventListener('keydown', function (e) {
            if (e.key === 'Enter') {
                this.click();
            }
        }, false);
        itemCounter.querySelector('.minus').addEventListener('click', function () {
            if (Number(inputField.value) > 1) {
                inputField.value = Number(inputField.value) - 1;
            }
        }, false);
        itemCounter.querySelector('.minus').addEventListener('keydown', function (e) {
            if (e.key === 'Enter') {
                this.click();
            }
        }, false);
    });

}
