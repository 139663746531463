import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        const anySwiper = document.querySelector('.swiper:not(.product-images)');

        if (!anySwiper) return;

        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Pagination, Autoplay],
                preloadImages: false,
                autoplay: {
                    delay: 7000
                },
                lazy: true,
                loop: true,
                direction: "vertical",
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                }
            });

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.main-page-products-wrapper.slider1 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button1',
                    prevEl: '.swiper-button-prev.slider-button1'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider1.update();
            });

            const slider2 = new Swiper('.main-page-products-wrapper.slider2 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button2',
                    prevEl: '.swiper-button-prev.slider-button2'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider2.on('breakpoint', function () {
                slider2.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider2.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider2.update();
            });

            //PRODUCERS
            const producersBanner = new Swiper('.producers-banner .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.producer-button',
                    prevEl: '.swiper-button-prev.producer-button'
                },
                loop: false,
                spaceBetween: 30,
                breakpoints: {
                    1: {
                        slidesPerView: 2,
                        spaceBetween: 15
                    },
                    510: {
                        slidesPerView: 3
                    },
                    601: {
                        slidesPerView: 4
                    },
                    1100: {
                        slidesPerView: 5
                    },
                    1280: {
                        slidesPerView: 6
                    },
                    1681: {
                        slidesPerView: 7
                    }
                }
            });
        });
    }, false);
}