import { on } from 'delegated-events';
export default function () {
    const headerLocationTrigger = document.querySelector(".header-location-trigger");
    on("click", ".header-location-trigger", function () {
        headerLocationTrigger.classList.add("open");
        document.addEventListener("click", hideMap);
    });
    function hideMap() {
        if (event.target.classList.contains("header-location-open")) {
            return;
        };
        headerLocationTrigger.classList.remove("open");
        document.removeEventListener("click", hideMap);
    }
}